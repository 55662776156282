<script>
import Layout from "../../layouts/auth";
import { authMethods } from "@/state/helpers";
import appConfig from "@/app.config";

/**
 * Login component
 */
export default {
  page: {
    title: "Confirm Your Email",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout },
  data() {
    return {
      email: "",
      authError: null,
      tryingToLogIn: false,
      isAuthError: false,
      invalidEmail : "Your account or Email is incorrect.",
      stateEmail : true,
      loading : false,
      landing : process.env.VUE_APP_LANDING
    };
  },
  mounted() {
    // this.$ga.page('/verify')
    this.$ga.event(process.env.VUE_APP_GA_VERIFY_EMAIL, 'actionx', 'labelx', 1)
  },
  methods: {
    ...authMethods,
    landingPage(){
      window.open(this.landing,"_self")
    },
    // Try to log the user in with the username
    // and password they provided.
    tryToLogIn() {
      this.tryingToLogIn = true;
      // Reset the authError if it existed.
      this.authError = null;
      this.loading = true;
      return (
        this.verify({
          vcode: this.email,
        })
          // eslint-disable-next-line no-unused-vars
          .then(token => {
            this.tryingToLogIn = false;
            this.isAuthError = false;
            this.stateEmail = true;
            this.loading = false;
            // Redirect to the originally requested page, or to the home page
            this.$router.push(
              this.$route.query.redirectFrom || { name: "Confirmed" }
            );
          })
          .catch(error => {
            this.tryingToLogIn = false;
            // this.authError = error ? error : "";
            this.invalidEmail = error ? error : "";
            this.stateEmail = false;
            this.loading = false;
            // this.isAuthError = true;
          })
      );
    }
  }
};
</script>

<template>
  <Layout>
    <div class="d-flex justify-content-center align-items-center verify-signup-page">
      <div class="mx-4 px-4 left-verify">
          <img src="@/assets/images/signup.png"  alt class="cursor-pointer px-2 img-fluid" />
      </div>
      <div class="mx-4 px-4 mpx"></div>
      <div class="row justify-content-center mt-4 mb-4 pb-4 right-verify">
        <div class="mt-4">
          <div class="card overflow-hidden card-no-shadow">
            <h1 class="text-center h1-title ">Confirm Your Email</h1>
            <p class="default-text-style">Enter the code received via email, or click on the link, <b>to confirm your account.</b></p>
            <div class="card-body pt-4">
              <b-alert v-model="isAuthError" variant="danger" class="mt-3" dismissible>{{authError}}</b-alert>
              <b-form class="p-2" @submit.prevent="tryToLogIn">
                <b-form-group id="input-group-1" class="py-2" :invalid-feedback="invalidEmail" :state="stateEmail"   label-class="label-input" label-for="input-1">
                  <b-form-input id="input-1" class="login-input-style" v-model="email" type="text" placeholder="Verification code"></b-form-input>
                </b-form-group>
                <div class="mt-2 pt-2 d-flex justify-content-between align-items-center">
                    <router-link tag="a" to="/resend" class="default-text-style"><span class="text-blue">Send again</span></router-link>
                    <button class="btn base-btn bg-grey">
                      <i v-show="loading" class="bx bx-loader font-size-16 bx-tada"></i>
                      <span class="default-text-style">Verify</span>
                      </button>
                </div>
              </b-form>
            </div>
            <!-- end card-body -->
          </div>
          <!-- end card -->
          <!-- end row -->
        </div>
        <!-- end col -->
      </div>
    </div>
    <!-- end row -->
  </Layout>
</template>

<style lang="scss" module></style>
